import * as types from '../mutation-types'
import axios from '../../common/axios.js'
import _ from 'lodash'

// initial state
const state = {
	affichage: 'synthetique',
	loading: 0,
	loading_search: 0,
	feature_over: null,
	parcelle_on_id: null,			//AS--> Id de la parcelle sélectionnée
	parcelle_on_id_old: null,		//AS--> Id de la parcelle qui était sélectionnée avant
	parcelle_on_detailled: null,	//AS--> Donnée complètes de la parcelle
	parcelle_on: null,				//AS--> Données résumées de la parcelle
	favoris: [],

	menu_parcelles_filtres: [],
	menu_parcelles_affiche: true,
	parcelles_area_limit: 20,
	parcelles_geojson: false,
	parcelles_geojson_opacity: 8,
	parcelles_geojson_default_style: {
		fillColor :		'#FFC700',
		fillOpacity :	0.08,
		strokeColor :	'#FF7A00',
		strokeOpacity :	1
	},
	parcelles_geojson_axiostoken: false,

	parcelles_areaTooBig: false,

	parcelles_list_order: 'commune',
	parcelles_list_order_direction: 'asc'
}

// getters
const getters = {
	parcelleGeojson: function(){
		return 'test';
	},
	getTotalParcelleFavoris: (state) => {
		return state.favoris.length
	},
	hasFilters_parcelles: (state, getters, rootState) => (filtre) => {
		let filtres = _.cloneDeep(state.menu_parcelles_filtres)
		delete filtres.groupe
		return Object.keys(filtres).length > 0;
	},
	getNbrParcelles: (state) => {
		return state.parcelles_geojson?.features?.length ?? null
	}
}

// actions
const actions = {
	setFeatureOver({ commit, state, rootState }, feature) {
		commit('PARCELLE_FEATURE_OVER', feature);
	},
	setParcelleOnId({ commit, state, rootState }, parcelle_id) {
		let self = this
		commit('PARCELLE_PARCELLE_ON_ID_OLD', parcelle_id ? parcelle_id : state.parcelle_on_id);
		commit('PARCELLE_PARCELLE_ON_ID', parcelle_id);
		commit('PARCELLE_PARCELLE_ON', null);
		commit('PARCELLE_PARCELLE_ON_DETAILLED', null);
		commit('PARCELLE_AFFICHAGE', 'synthetique');
		if(parcelle_id){
			commit('PARCELLE_ADD_LOADING');

			axios.get('api=inex/parcelles/{parcelle_id}?information_type=summary')
				.then(function (response) {
					commit('PARCELLE_REMOVE_LOADING');
					// console.log(response.data);
					if(response.data && response.data){
						commit('PARCELLE_PARCELLE_ON', response.data)
					}
				}).catch(function (error) {
					commit('PARCELLE_REMOVE_LOADING');

				})
		}
	},
	getParcelleOnDetailled({ commit, state, rootState }) {
		let self = this
		if(state.parcelle_on_id){
			axios.get('api=inex/parcelles/{parcelle_id}?information_type=all')
				.then(function (response) {
					if(response.data && response.data){
						console.log(response.data);
						commit('PARCELLE_PARCELLE_ON_DETAILLED', response.data)
					}
				}).catch(function (error) {

				})
		}
	},
	setParcelleOn({ commit, state, rootState }, parcelle) {
		commit('PARCELLE_PARCELLE_ON', parcelle);
	},
	setParcelleAffichage({ commit, state, rootState, dispatch }, affichage) {
		commit('PARCELLE_AFFICHAGE', affichage);
		if(affichage == 'detaille' && !state.parcelle_on_detailled){
			dispatch('getParcelleOnDetailled');
		}
	},
	addParcelleFavoris({ commit, state, rootState, dispatch }) {
		axios.post('api=inex/users/{user_email}/views/{view_code}/favorites/parcelles/{parcelle_id}')
			.then(function (response) {
				dispatch('getParcellesFavoris');
			}).catch(function (error) {

			})
	},
	delParcelleFavoris({ commit, state, rootState, dispatch }, parcelle_id) {
		axios.delete('api=inex/users/{user_email}/views/{view_code}/favorites/parcelles/'+parcelle_id)
			.then(function (response) {
				dispatch('getParcellesFavoris');
			}).catch(function (error) {

			})
	},
	getParcellesFavoris({ commit, state, rootState }) {
		axios.get('api=inex/users/{user_email}/views/{view_code}/favorites/parcelles')
			.then(function (response) {
				let favoris = []
				if(response.data.favorites && response.data.favorites.length){
					response.data.favorites.forEach(function(_favoris){
						if(_favoris.value){
							favoris.push(_favoris)
						}else{
							favoris.push({value:_favoris, name: _favoris})
						}
					})
				}
				commit('PARCELLE_PARCELLES_FAVORIS', favoris);
			}).catch(function (error) {

			})

	},
	loadParcelles({ commit, state, rootState }) {

		if(rootState.ui.screenPdfOn) return;

		// if(!rootState.ui.map_zone_google.geoJsonActif) return

		//AS--> Annule la précédente requête XHR si elle est encore en cours d'exécution
		if (state.parcelles_geojson_axiostoken) state.parcelles_geojson_axiostoken.cancel('Start new search, stop active search');

		//AS--> Stock un nouveau token d'exécution Axios
		commit('PARCELLE_PARCELLES_GEOJSON_AXIOS_TOKEN', axios.CancelToken.source())
		commit('UI_ADD_LOADING_LAYER')
		commit('PARCELLE_SEARCH_ADD_LOADING');

		let vars = '?'+ encodeURI('geometry=' + JSON.stringify(rootState.ui.map_zone_google.geoJsonActif) + '&query_filter=' + JSON.stringify(state.menu_parcelles_filtres))

		axios.get('api=inex/calques/parcelles'+vars, { cancelToken: state.parcelles_geojson_axiostoken.token })
			.then(function (response) {
				commit('UI_REMOVE_LOADING_LAYER')
				commit('PARCELLE_PARCELLES_GEOJSON_AXIOS_TOKEN', null)
				response.data.properties = {
					'style': state.parcelles_geojson_default_style
				}
				commit('PARCELLE_PARCELLES_GEOJSON', response.data)
				commit('PARCELLE_PARCELLES_AREA_TOO_BIG', false)
				
			}).catch(function (error) {
				commit('UI_REMOVE_LOADING_LAYER')
				if(error.response?.status == 413){
					commit('PARCELLE_PARCELLES_GEOJSON', null)
					commit('PARCELLE_PARCELLES_AREA_TOO_BIG', true)
				}
				else if(error.response?.status == 422){
					commit('PARCELLE_PARCELLES_GEOJSON', null)
				}
			}).finally(function(){
				commit('PARCELLE_SEARCH_REMOVE_LOADING');
			})
	},
	setParcellesOpacity({ commit, state, rootState }, opacity) {
		commit('PARCELLE_PARCELLES_GEOJSON_OPACITY', null)
		setTimeout(function(){
			commit('PARCELLE_PARCELLES_GEOJSON_OPACITY', opacity)
		}, 1)
	},
	setParcellesListOrder({ commit }, parcelles_list_order) {
		if(state.parcelles_list_order != parcelles_list_order){
			commit('PARCELLE_PARCELLES_LIST_ORDER', parcelles_list_order)
			commit('PARCELLE_PARCELLES_LIST_ORDER_DIRECTION', 'asc')
		}else{
			commit('PARCELLE_PARCELLES_LIST_ORDER_DIRECTION', state.parcelles_list_order_direction == 'asc' ? 'desc' : 'asc')
		}
	}
}

// mutations
const mutations = {
	[types.PARCELLE_FEATURE_OVER](state, feature) {
		state.feature_over = feature;
	},
	[types.PARCELLE_PARCELLE_ON_ID](state, parcelle_id) {
		console.time('PARCELLE_PARCELLE_ON_ID' + ' - ' + parcelle_id);
		state.parcelle_on_id = parcelle_id;
		console.timeEnd('PARCELLE_PARCELLE_ON_ID' + ' - ' + parcelle_id);
	},
	[types.PARCELLE_PARCELLE_ON_ID_OLD](state, parcelle_id) {
		console.time('PARCELLE_PARCELLE_ON_ID_OLD');
		state.parcelle_on_id_old = parcelle_id;
		console.timeEnd('PARCELLE_PARCELLE_ON_ID_OLD');
	},
	[types.PARCELLE_PARCELLE_ON](state, parcelle) {
		console.time('PARCELLE_PARCELLE_ON');
		state.parcelle_on = parcelle;
		console.timeEnd('PARCELLE_PARCELLE_ON');
	},
	[types.PARCELLE_PARCELLE_ON_DETAILLED](state, parcelle) {
		console.time('PARCELLE_PARCELLE_ON_DETAILLED');
		state.parcelle_on_detailled = parcelle;
		console.timeEnd('PARCELLE_PARCELLE_ON_DETAILLED');
	},
	[types.PARCELLE_AFFICHAGE](state, affichage) {
		console.time('PARCELLE_AFFICHAGE');
		state.affichage = affichage;
		console.timeEnd('PARCELLE_AFFICHAGE');
	},
	[types.PARCELLE_SEARCH_ADD_LOADING](state) {
		console.time('PARCELLE_SEARCH_ADD_LOADING');
		state.loading_search = true;
		console.timeEnd('PARCELLE_SEARCH_ADD_LOADING');
	},
	[types.PARCELLE_SEARCH_REMOVE_LOADING](state) {
		console.time('PARCELLE_SEARCH_REMOVE_LOADING');
		state.loading_search = false
		console.timeEnd('PARCELLE_SEARCH_REMOVE_LOADING');
	},
	[types.PARCELLE_ADD_LOADING](state) {
		console.time('PARCELLE_ADD_LOADING');
		state.loading = state.loading +1;
		console.timeEnd('PARCELLE_ADD_LOADING');
	},
	[types.PARCELLE_REMOVE_LOADING](state) {
		console.time('PARCELLE_REMOVE_LOADING');
		let loading = state.loading -1;
		if(loading < 0) loading = 0;
		state.loading = loading
		console.timeEnd('PARCELLE_REMOVE_LOADING');
	},
	[types.PARCELLE_PARCELLES_FAVORIS](state, favoris) {
		console.time('PARCELLE_PARCELLES_FAVORIS');
		state.favoris = favoris
		console.timeEnd('PARCELLE_PARCELLES_FAVORIS');
	},
	[types.PARCELLE_MENU_PARCELLES_FILTRES](state, menu_parcelles_filtres) {
		console.time('PARCELLE_MENU_PARCELLES_FILTRES');
		state.menu_parcelles_filtres = menu_parcelles_filtres
		console.timeEnd('PARCELLE_MENU_PARCELLES_FILTRES');
	},
	[types.PARCELLE_MENU_PARCELLES_AFFICHE](state, menu_parcelles_affiche) {
		console.time('PARCELLE_MENU_PARCELLES_AFFICHE');
		state.menu_parcelles_affiche = menu_parcelles_affiche
		console.timeEnd('PARCELLE_MENU_PARCELLES_AFFICHE');
	},
	[types.PARCELLE_PARCELLES_GEOJSON](state, parcelles_geojson) {
		console.time('PARCELLE_PARCELLES_GEOJSON');
		state.parcelles_geojson = parcelles_geojson
		console.timeEnd('PARCELLE_PARCELLES_GEOJSON');
	},
	[types.PARCELLE_PARCELLES_GEOJSON_AXIOS_TOKEN](state, parcelles_geojson_axiostoken) {
		console.time('PARCELLE_PARCELLES_GEOJSON_AXIOS_TOKEN');
		state.parcelles_geojson_axiostoken = parcelles_geojson_axiostoken
		console.timeEnd('PARCELLE_PARCELLES_GEOJSON_AXIOS_TOKEN');
	},
	[types.PARCELLE_PARCELLES_GEOJSON_OPACITY](state, parcelles_geojson_opacity) {
		console.time('PARCELLE_PARCELLES_GEOJSON_OPACITY');
		state.parcelles_geojson_opacity = parcelles_geojson_opacity
		console.timeEnd('PARCELLE_PARCELLES_GEOJSON_OPACITY');
	},
	[types.PARCELLE_PARCELLES_AREA_LIMIT](state, parcelles_area_limit) {
		console.time('PARCELLE_PARCELLES_AREA_LIMIT');
		state.parcelles_area_limit = parcelles_area_limit
		console.timeEnd('PARCELLE_PARCELLES_AREA_LIMIT');
	},
	[types.PARCELLE_PARCELLES_AREA_TOO_BIG](state, isTooBig) {
		console.time('PARCELLE_PARCELLES_AREA_TOO_BIG');
		state.parcelles_areaTooBig = isTooBig
		console.timeEnd('PARCELLE_PARCELLES_AREA_TOO_BIG');
	},
	[types.PARCELLE_PARCELLES_LIST_ORDER](state, parcelles_list_order) {
		console.time('PARCELLE_PARCELLES_LIST_ORDER');
		state.parcelles_list_order = parcelles_list_order
		console.timeEnd('PARCELLE_PARCELLES_LIST_ORDER');
	},
	[types.PARCELLE_PARCELLES_LIST_ORDER_DIRECTION](state, parcelles_list_order_direction) {
		console.time('PARCELLE_PARCELLES_LIST_ORDER_DIRECTION');
		state.parcelles_list_order_direction = parcelles_list_order_direction
		console.timeEnd('PARCELLE_PARCELLES_LIST_ORDER_DIRECTION');
	},
}

export default {
	state,
	getters,
	actions,
	mutations
}