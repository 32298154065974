//RZ--> Import des styles CSSS
// import vuejs_dialog from 'vuejs-dialog/dist/vuejs-dialog.min.css'
import style_main from './scss/main.scss'


import * as VueGoogleMaps from 'vue2-google-maps';
import * as Sentry from '@sentry/vue';
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from './store/store'

import VuejsDialog from 'vuejs-dialog'
import HighchartsVue from 'highcharts-vue'

import axios from './common/axios.js'
import _ from 'lodash'


import VueTelInput from 'vue-tel-input';
const globalOptions = {
    preferredCountries: ['fr', 'be', 'lu'],
    autoDefaultCountry: true,
    mode: 'national',
    autoFormat: true,
    inputOptions: {
      autocomplete: true
    },
    validCharactersOnly: true
};

//gestion de l'environnement pour Sentry
let environment = '';
//Si on est en local ou sur le serveur de dev, on est en environnement de développement
if (/localhost|^192\.168|dev\.oliv\.fr/.test(window.location.hostname)) {
  environment = 'development';

  //Si on trouve ".r7." dans le nom de domaine, on est en environnement de préproduction
} else if (/\.r7\./.test(window.location.hostname)) {
  environment = 'preproduction';

  //Sinon, on est en environnement de production
} else {
  environment = 'production';
}

if(environment != 'development'){
  Sentry.init({
    Vue,
    environment,
    dsn: 'https://debfd3cbebafd4c500245c79a59c9755@o4504413636722688.ingest.sentry.io/4506654828331008',
    integrations: [
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      })
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
}


import Hotjar from 'vue-hotjar'

// import App from './components/app.vue';

import VTooltip from 'v-tooltip'

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

/* import Vue plugin */
import VueSvgInlinePlugin from "vue-svg-inline-plugin";
VueSvgInlinePlugin.install(Vue);


Vue.use(VTooltip)
Vue.use(VueI18n)
Vue.use(VuejsDialog, {
  html: true,
  loader: false,
  okText: 'Ok',
  cancelText: 'Annuler'
})
Vue.use(HighchartsVue)
Vue.use(VueTelInput, globalOptions)


const urlParams = new URLSearchParams(window.location.search);
const debugs = {
  'edition_matiere': urlParams.has('debug_edition_matiere')
}

Vue.mixin({
  data() {
    return {
      debugs: debugs
    }
  },
  methods: {
    //AS--> Attend un objet {fre:'blabla', eng:'blibli', ...}
    i18n: function (trads, replacement_if_undefined) {
      if(trads === null) return '';
      let trad = '';
      if(typeof trads == 'object'){

        //AS--> Si la traduction dans la langue demandée existe
        if(trads[this.$store.state.ui.langue]){
          return trads[this.$store.state.ui.langue];

        //AS--> Sinon, renvoie la première trad trouvée
        }else{
          _.forEach(trads, function(val, lang){
            if(!trad) trad = val;
          })
        }

      }else{
        if(typeof trads == "undefined"){
          return replacement_if_undefined ? replacement_if_undefined : ''
        }else if(typeof trads == 'string' && ['[', '{'].includes(trads.substring(0,1))){
          trads = JSON.parse(trads);
          return this.i18n(trads);
        
        }else{
          trad = trads;

        }
      }

      return trad;
    },
  },
})

//AS--> Charge les données textuelles de l'app
export const i18n = new VueI18n({
	locale: 'fr'
})

axios.get('/plugin_projet/plugin_projet_iannuaire/init_app?langue='+window.langue_code)
  .then(function (response) {
  	i18n.setLocaleMessage('fr', response.data.i18ns);

    //AS--> Hotjar
    if(response.data.sourcing && response.data.sourcing.hotjar_site_id){
      Vue.use (Hotjar, {
        id: response.data.sourcing.hotjar_site_id+''
      })
    }

    import('./components/app.vue').then(function(App){
        const app = new Vue({
              // router,
              i18n,
              store,
              data: {
                init_app : response.data
              },
              render: h => h(App.default)
          }).$mount('#app');
      })


  }).catch(function (error) {})

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBlIuSr2lgxkZb-OSX4u2eAaL3Nc64PoOQ',
    // libraries: ['places','geometry']
    // v: 'OPTIONAL VERSION NUMBER',
    libraries: ['geometry','visualization'], //// If you need to use place input 
  }
});



Vue.component('TextLimit', () => import('./common/ui/text_limit.vue'));


Vue.component('IconLogo', () => import('./icons/IconLogo'));
Vue.component('IconBase', () => import('./icons/IconBase'));
Vue.component('IconInfo', () => import('./icons/IconInfo'));
Vue.component('IconAttention', () => import('./icons/IconAttention'));
Vue.component('IconGraph5', () => import('./icons/IconGraph5'));
Vue.component('IconMap', () => import('./icons/IconMap'));
Vue.component('IconFactory', () => import('./icons/IconFactory'));
Vue.component('IconBalle', () => import('./icons/IconBalle'));
Vue.component('IconFilter', () => import('./icons/IconFilter'));
Vue.component('IconFilters', () => import('./icons/IconFilter')); //AS--> Sécu en doublure de IconFilter
Vue.component('IconFilter2', () => import('./icons/IconFilter2'));
Vue.component('IconPhone', () => import('./icons/IconPhone'));
Vue.component('IconPhoneFull', () => import('./icons/IconPhoneFull'));
Vue.component('IconOutput', () => import('./icons/IconOutput'));
Vue.component('IconSetting', () => import('./icons/IconSetting'));
Vue.component('IconActivites', () => import('./icons/IconActivites'));
Vue.component('IconSecteur', () => import('./icons/IconSecteur'));
Vue.component('IconMarket', () => import('./icons/IconMarket'));
Vue.component('IconIndicator', () => import('./icons/IconIndicator'));
Vue.component('IconWarning', () => import('./icons/IconWarning'));
Vue.component('IconPencil', () => import('./icons/IconPencil'));
Vue.component('IconPencil2', () => import('./icons/IconPencil2'));
Vue.component('IconPencil3', () => import('./icons/IconPencil3'));
Vue.component('IconPencil4', () => import('./icons/IconPencil4'));
Vue.component('IconSave', () => import('./icons/IconSave'));
Vue.component('IconMenu', () => import('./icons/IconMenu'));
Vue.component('IconClose', () => import('./icons/IconClose'));
Vue.component('IconSelected', () => import('./icons/IconSelected'));
Vue.component('IconSelectedDark', () => import('./icons/IconSelectedDark'));
Vue.component('IconArrow', () => import('./icons/IconArrow'));
Vue.component('IconDownload', () => import('./icons/IconDownload'));
Vue.component('IconCheck', () => import('./icons/IconCheck'));
Vue.component('IconStarFull', () => import('./icons/IconStarFull'));
Vue.component('IconSwitch', () => import('./icons/IconSwitch'));
Vue.component('IconSkypeEmpty', () => import('./icons/IconSkypeEmpty'));
Vue.component('IconStarCheckbox', () => import('./icons/IconStarCheckbox'));
Vue.component('IconStarEmpty', () => import('./icons/IconStarEmpty'));
Vue.component('IconMail', () => import('./icons/IconMail'));
Vue.component('IconMinusCircle', () => import('./icons/IconMinusCircle'));
Vue.component('IconTrash', () => import('./icons/IconTrash'));
Vue.component('IconTrashEmpty', () => import('./icons/IconTrashEmpty'));
Vue.component('IconSearch', () => import('./icons/IconSearch'));
Vue.component('IconScreen', () => import('./icons/IconScreen'));
Vue.component('IconFullScreen', () => import('./icons/IconFullScreen'));
Vue.component('IconParcelle', () => import('./icons/IconParcelle'));
Vue.component('IconParcelle2', () => import('./icons/IconParcelle2'));
Vue.component('IconEntreprise', () => import('./icons/IconEntreprise'));
Vue.component('IconAdresse', () => import('./icons/IconAdresse'));
Vue.component('IconAdresse2', () => import('./icons/IconAdresse2'));
Vue.component('IconClose2', () => import('./icons/IconClose2'));
Vue.component('IconPlus', () => import('./icons/IconPlus'));
Vue.component('IconSave2', () => import('./icons/IconSave2'));
Vue.component('IconDownload2', () => import('./icons/IconDownload2'));
Vue.component('IconSearch2', () => import('./icons/IconSearch2'));
Vue.component('IconOutils', () => import('./icons/IconOutils'));
Vue.component('IconRegle', () => import('./icons/IconRegle'));
Vue.component('IconDown', () => import('./icons/IconDown'));
Vue.component('IconVoiture', () => import('./icons/IconVoiture'));
Vue.component('IconCamion', () => import('./icons/IconCamion'));
Vue.component('IconTarget', () => import('./icons/IconTarget'));
Vue.component('IconLoading', () => import('./icons/IconLoading'));
Vue.component('IconMix', () => import('./icons/IconMix'));
Vue.component('IconCarte', () => import('./icons/IconCarte'));
Vue.component('IconListe', () => import('./icons/IconListe'));
Vue.component('IconGraph', () => import('./icons/IconGraph'));
Vue.component('IconOeil', () => import('./icons/IconOeil'));
Vue.component('IconFavParcelles', () => import('./icons/IconFavParcelles'));
Vue.component('IconFavRecherches', () => import('./icons/IconFavRecherches'));
Vue.component('IconFavSites', () => import('./icons/IconFavSites'));
Vue.component('IconFavSurfaces', () => import('./icons/IconFavSurfaces'));
Vue.component('IconPin', () => import('./icons/IconPin'));
Vue.component('IconCanvas0', () => import('./icons/IconCanvas0'));
Vue.component('IconCanvas1', () => import('./icons/IconCanvas1'));
Vue.component('IconCalandar', () => import('./icons/IconCalandar'));
Vue.component('IconImage', () => import('./icons/IconImage'));
Vue.component('IconParcellesFilter', () => import('./icons/IconParcellesFilter'));
Vue.component('IconXls', () => import('./icons/IconXls'));
Vue.component('IconExclure', () => import('./icons/IconExclure'));
Vue.component('IconChevron', () => import('./icons/IconChevron'));
Vue.component('IconChevron2', () => import('./icons/IconChevron2'));
Vue.component('IconCentreAide', () => import('./icons/IconCentreAide'));
Vue.component('IconCAPas', () => import('./icons/IconCAPas'));
Vue.component('IconCAChat', () => import('./icons/IconCAChat'));
Vue.component('IconCrm', () => import('./icons/IconCrm'));
Vue.component('IconPerson', () => import('./icons/IconPerson'));
Vue.component('IconDoc', () => import('./icons/IconDoc'));
Vue.component('IconBtnFlecheCarre', () => import('./icons/IconBtnFlecheCarre'));
Vue.component('IconSitesDispos', () => import('./icons/IconSitesDispos'));
Vue.component('IconTonnesDispos', () => import('./icons/IconTonnesDispos'));
Vue.component('IconPotentielMetha', () => import('./icons/IconPotentielMetha'));
Vue.component('IconGisement', () => import('./icons/IconGisement'));
Vue.component('IconHot', () => import('./icons/IconHot'));
Vue.component('IconWaste', () => import('./icons/IconWaste'));
Vue.component('IconLieu', () => import('./icons/IconLieu'));
Vue.component('IconInfoLarge', () => import('./icons/IconInfoLarge'));
Vue.component('IconRefresh', () => import('./icons/IconRefresh'));
Vue.component('IconTooHigh', () => import('./icons/IconTooHigh'));
Vue.component('IconBigScreen', () => import('./icons/IconBigScreen'));
Vue.component('IconAction', () => import('./icons/IconAction'));
Vue.component('IconTrash2', () => import('./icons/IconTrash2'));
Vue.component('IconHandle', () => import('./icons/IconHandle'));
Vue.component('IconSuivi', () => import('./icons/IconSuivi'));
Vue.component('IconCalandarDays', () => import('./icons/IconCalandarDays'));
Vue.component('IconBuilding', () => import('./icons/IconBuilding'));
Vue.component('IconParcellesX2', () => import('./icons/IconParcellesX2'));




// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

// Pendo
(function(apiKey){
    (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
    v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
        o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
        y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
        z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
})('b6d18c51-918a-4377-6c59-e666553e9a5e');



/***********************************************
* Disable select-text script- © Dynamic Drive (www.dynamicdrive.com)
* This notice MUST stay intact for legal use
* Visit http://www.dynamicdrive.com/ for full source code
***********************************************/
if(environment != 'development'){

  //form tags to omit in NS6+:
  var omitformtags=['input', 'textarea', 'select']

  omitformtags=omitformtags.join('|')

  function disableselect(e){
    if (omitformtags.indexOf(e.target.tagName.toLowerCase())==-1)
      return false
  }

  function reEnable(){
    return true
  }
  
  function autorizeSelect(e){

    //AS--> Défini la modale d'affichage d'une fiche entreprise
    var modal_compagny = document.querySelector('.c-modal-company');
    //AS--> Défini la modale d'affichage d'une fiche parcelle
    var modal_parcelle = document.querySelector('#parcelle');
    //AS--> Défini l'infowindow de GoogleMap
    var info_window = document.querySelector('.gm-style-iw');
    //AS--> Défini tous les objets en editions
    var field_edit = document.querySelector('.edit');

    //AS--> On autorise le select dans une fiche entreprise ou parcelle
    if (
      (modal_compagny && modal_compagny.contains(e.target))
      || (modal_parcelle && modal_parcelle.contains(e.target))
      || (info_window && info_window.contains(e.target))
      || (field_edit && field_edit.contains(e.target))
      ){
      return true
    }

    //AS--> Sinon on refuse le select
    return false
  }

  if (typeof document.onselectstart!='undefined'){
    document.onselectstart=autorizeSelect
  }
  else{
    document.onmousedown=disableselect
    document.onmouseup=reEnable
  }

  //AS--> Add a javascript code to empty the clipboard just after copy
  //AS--> Retiré car ils veulent pouvoir copier des infos de la fiche entreprise
  // document.addEventListener('copy', function(e){
  //   e.clipboardData.setData('text/plain', '');
  //   e.preventDefault();
  // });
}